<template>
    <div class="app-container">
        <div class="filter-container">
            <img class="logo" :src="logo" />
            <el-input
                v-model="search"
                style="flex: 1"
                class="item"
                :placeholder="'Search user'"
                @keyup.enter.native="doSearch"
            />
            <el-button type="primary" class="item" @click="doSearch">
                Search
            </el-button>
        </div>
    </div>
</template>

<script>
import logo from '@/assets/logo_dark.svg';

export default {
    name: 'search',
    props: {},
    data() {
        return {
            logo,
            search: '',
        };
    },
    methods: {
        doSearch() {
            if (this.search) {
                this.$router.push(`/users?q=${this.search}`);
            }
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.app-container {
}
.filter-container {
    width: 100%;
    height: 200px;
    max-width: 600px;
    padding: 20px;
    background: white;
    border-radius: 10px;
    border: 1px solid #dcdfe6;
    margin: 0 auto 0;
    text-align: center;
    position: relative;
    top: calc((50vh - 145px) * 0.75); // vertically centered, then offset
    .logo {
        width: 200px;
        margin-bottom: 20px;
    }
    .el-button {
        width: 100%;
    }
}
</style>
